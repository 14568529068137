<template>
  <v-container fluid pb-5 mb-4>
    <div v-if="loading" class="primary--text mb-4">
      <v-progress-circular
        indeterminate
        color="primary"
        class="mr-2"
      ></v-progress-circular>

      Cargando los datos, por favor espere...
    </div>

    <v-card
      v-else
      class="mx-auto"
      max-width="100%"
      outlined
    >
      <v-card-title>
        Compra Confirmada con Éxito!
      </v-card-title>

      <template v-if="compra.metodo_pago_codigo === 'transferencia_bancaria'">
        <v-card-text>
          Para completar la compra usted debe transferir la suma de <b>{{ compra.importe_fmt }}</b> pesos argentinos a la siguiente cuenta:
        </v-card-text>

        <v-card-text>
          NACIÓN EMPRESA 24<br>
          CBU: 0110299620029900184377<br>
          ALIAS SALA.BOLETO.POSTE<br>
          Nro.de Cta: 92182990018437
        </v-card-text>
      </template>

      <template v-else>
        <v-card-text>
          Para completar su compra usted debe abonar la suma de <b>{{ compra.importe_fmt }}</b> pesos argentinos en Mercado Pago.
        </v-card-text>
      </template>

      <v-card-actions v-if="compra.estado_codigo === 'esperando'">
        <v-btn
          v-if="compra.metodo_pago_codigo === 'transferencia_bancaria'"
          color="success"
          :href="compra.print_datos_bancarios"
          target="_blank"
        >
          Imprimir
        </v-btn>

        <v-btn
          v-else
          color="success"
          :href="compra.mp_init_point"
        >
          Pagar
        </v-btn>
      </v-card-actions>

      <v-card-actions>
        <v-btn
          text
          small
          color="primary"
          :to="{ name: 'comprar' }"
          >
          Volver a Comprar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          text
          small
          color="primary"
          href="http://complejoamericano.com"
        >
          volver al sitio web principal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      loading: true,
      compra: {}
    }),
    methods: {
      fetch (id) {
        this.loading = true

        this.$http.get('cuenta/compra/'+id)
        .then((response) => {
          this.compra = response.data
        })
        .catch(() => {
          this.$router.push({ name: 'comprar' }, () => {
            this.$eventHub.$emit('snackbar-message', 'No se pudo cargar el contenido de la página solicitada')
          })
        })
        .then(() => {
          this.loading = false
        })
      }
    },

    mounted () {
      this.fetch(this.$route.params.id)
    }
  }

</script>
